import { XAxisProps, YAxisProps } from "recharts";
import { fontStyles } from "../theme/default";
import palette from "../theme/palette";

const CHART_ANIMATION_DURATION = 200;

export const areaStyleProps = {
  animationDuration: CHART_ANIMATION_DURATION,
  strokeWidth: 2,
};

export const barStyleProps = {
  animationDuration: CHART_ANIMATION_DURATION,
  strokeWidth: 0,
  maxBarSize: 50,
};

export const cartesianStyleProps = {
  horizontal: true,
  strokeWidth: 1,
  vertical: false,
};

export const lineStyleProps = {
  animationDuration: CHART_ANIMATION_DURATION,
  dot: false,
  strokeWidth: 3,
};

export const xAxisStyleProps: XAxisProps = {
  dy: 5,
  padding: { left: 20, right: 20 },
  strokeWidth: 1,
  tick: {
    fontFamily: "Ternary",
    fontSize: fontStyles.fontSize_ui,
    fontWeight: fontStyles.fontWeight_thin,
    stroke: palette.gray[700],
  },
};

export const yAxisStyleProps: YAxisProps = {
  dx: -5,
  strokeWidth: 1,
  tick: {
    fontFamily: "Ternary",
    fontSize: fontStyles.fontSize_ui,
    fontWeight: fontStyles.fontWeight_thin,
    stroke: palette.gray[700],
  },
};
