import palette from "./palette";

export const DATA_VIZ_COLORS = [
  palette.aqua[400],
  palette.alpenglow[400],
  palette.canyon[300],
  palette.oasis[400],
  palette.flamingo[200],
  palette.fairway[400],
  palette.pueblo[300],
  palette.nightfall[700],
  palette.yucca[700],
];

export const ECO_DATA_VIZ_COLORS = [
  palette.fairway[500],
  palette.oasis[500],
  palette.fairway[700],
  palette.nightfall[400],
  palette.fairway[600],
  palette.alpenglow[300],
  palette.fairway[200],
  palette.aqua[400],
  palette.alpenglow[700],
  palette.nightfall[100],
  palette.oasis[600],
  palette.pueblo[200],
  palette.nightfall[500],
];

export const fontStyles = {
  fontFamily_numbers: "San Francisco",
  fontFamily: "Aeonik",
  fontSize_base: "16px",
  fontSize_small: "0.7rem",
  fontSize_ui: "0.875rem",
  fontSize_xs: "0.55rem",
  fontWeight_bold: 700,
  fontWeight_regular: 400,
  fontWeight_semiBold: 600,
  fontWeight_thin: 100,
  h1_fontSize: "2.125rem",
  h1_fontWeight: 700,
  h2_fontSize: "2rem",
  h2_fontWeight: 600,
  h3_fontSize: "1.5rem",
  h3_fontWeight: 600,
  h4_fontSize: "1.25rem",
  h4_fontWeight: 600,
  h5_fontSize: "0.875rem",
  h5_fontWeight: 400,
  h6_fontSize: "0.75rem",
  h6_fontWeight: 400,
};

export const sizesAndPositions = {
  borderRadius_1: "6px",
  borderRadius_2: "8px",
  borderRadius_3: "12px",
  borderRadius_4: "16px",
  lineHeight_ui: 1.5,
  lineHeight: 1.25,
  size_jumbo: "4rem",
  size_large: "3rem",
  size_medium: "2.5rem",
  size_small: "2rem",
  size_tiny: "1.5rem",
  space_jumbo: "3.75rem",
  space_lg: "1.5rem",
  space_md: "1rem", // Root Font Size (16px)
  space_sm: "0.75rem",
  space_xl: "2rem",
  space_xs: "0.5rem",
  space_xxl: "3rem",
  space_xxs: "0.25rem",
  zIndex_100: 100,
  zIndex_1600: 1600,
  zIndex_200: 200,
  zIndex_400: 400,
  zIndex_800: 800,
};

const globalColors = {
  background_color_disabled: palette.gray[200],
  background_color: palette.gray[50],
  backgroundColor_successPrimary_hover: palette.palm[500],
  bar_chart_cursor_fill: palette.gray[200],
  border_color_dark: palette.gray[1000],
  border_color: palette.gray[50],
  box_shadow: "rgba(0, 0, 0, 0.2)",
  chart_axis_text: palette.gray[1000],
  chart_cartesian_grid_lines: palette.gray[200],
  chart_cursor_line_color: palette.gray[800],
  chart_legend_header: palette.gray[100],
  cloud_status_warning: palette.poppy[300],
  data_visualization_colors: DATA_VIZ_COLORS,
  divider_color: palette.gray[100],
  elevated_background_color: palette.gray[0],
  feedback_negative_background: palette.coral[100],
  feedback_negative_outline: palette.coral[600],
  feedback_negative: palette.coral[500],
  feedback_neutral_background: palette.gray[50],
  feedback_neutral_outline: palette.gray[600],
  feedback_neutral: palette.gray[800],
  feedback_positive_background: palette.palm[0],
  feedback_positive_outline: palette.palm[500],
  feedback_positive: palette.palm[400],
  feedback_warn_background: palette.poppy[0],
  feedback_warn_outline: palette.poppy[500],
  feedback_warn: palette.poppy[400],
  input_background_color: palette.gray[0],
  link_color_hover: palette.skyBlue[400],
  link_color: palette.skyBlue[500],
  loading_outline_line_color_bold: palette.gray[500],
  loading_outline_line_color: palette.gray[400],
  loading_skeleton_bg_color: palette.gray[200],
  loading_skeleton_fg_color: palette.gray[50],
  loading_spinner_color: palette.skyBlue[700],
  overlay_background_color: "rgba(30, 35, 40, 0.4)",
  panel_backgroundColor: palette.gray[0],
  primary_color_background_inverse: palette.skyBlue[100],
  primary_color_background: palette.skyBlue[400],
  primary_color_border: palette.skyBlue[400],
  primary_color_focus: palette.skyBlue[500],
  primary_color_hover: palette.skyBlue[500],
  primary_color_text: palette.skyBlue[400],
  secondary_color_background_hover: palette.gray[200],
  secondary_color_background: palette.gray[100],
  secondary_color_border: palette.gray[200],
  secondary_color: palette.gray[500],
  select_color: palette.gray[1000],
  select_control_border_color_focused: palette.gray[0],
  select_control_border_color: palette.gray[200],
  select_is_active: palette.alpenglow[300],
  side_drawer_background_color: palette.gray[0],
  side_drawer_header_background_color: palette.gray[1000],
  side_drawer_header_text_color: palette.gray[0],
  switch_color_off: palette.gray[400],
  switch_color_on: palette.palm[400],
  table_cell_background_color_selected: palette.gray[600],
  table_cell_background_color: palette.gray[0],
  table_cell_background_color_masked: palette.gray[200],
  table_footer_background_color: palette.gray[300],
  table_header_background_color: palette.gray[1000],
  table_header_text_color: palette.gray[0],
  table_row_background_color_hover: palette.gray[200],
  tag_background_color_danger: palette.coral[100],
  tag_background_color_primary: palette.aqua[400],
  tag_background_color_warning: palette.poppy[100],
  tag_background_color: palette.gray[200],
  tag_button_background_color_danger_hover: palette.coral[500],
  tag_button_background_color_hover: palette.gray[500],
  tag_button_background_color_warning_hover: palette.poppy[300],
  tag_button_color_danger: palette.coral[600],
  tag_button_color_warning: palette.poppy[500],
  tag_button_color: palette.gray[600],
  text_color_caption: palette.gray[600],
  text_color_disabled: palette.gray[500],
  text_color_inverse: palette.gray[0],
  text_color_placeholder: palette.gray[500],
  text_color_secondary_disabled: palette.gray[400],
  text_color_secondary: palette.gray[500],
  text_color: palette.gray[1000],
  tooltip_background_color: "rgba(0, 0, 0, 0.9)",
  tooltip_disabled_cell_color: palette.gray[300],
  tooltip_text_color_hover: palette.aqua[400],
  tooltip_text_color: palette.gray[0],
};

const featureSpecificColors = {
  alert_event_feed_alert_event_node: palette.coral[500],
  alert_event_feed_rule_event_node: palette.skyBlue[400],
  alert_event_feed_timeline: palette.gray[500],
  aws_cud_color_covered: palette.alpenglow[300],
  aws_cud_color_estimated: palette.palm[400],
  aws_cud_color_on_demand: palette.nightfall[400],
  aws_cud_color_vis_0_sp_cost: palette.nightfall[400],
  aws_cud_color_vis_1_ri_cost: palette.alpenglow[300],
  aws_cud_color_vis_2_on_demand_cost: palette.poppy[300],
  aws_cud_color_vis_3_unused_cost: palette.coral[500],
  aws_cud_color_vis_lost: palette.coral[500],
  aws_cud_color_vis_saved: palette.palm[400],
  big_query_duration_1: palette.gray[200],
  big_query_duration_2: palette.gray[400],
  big_query_duration_3: palette.gray[500],
  big_query_duration_4: palette.gray[600],
  budgets_chart_fill_actual: palette.nightfall[400],
  budgets_chart_fill_highlighted_version: palette.alpenglow[100],
  budgets_chart_fill_latest_version: palette.palm[0],
  budgets_chart_fill_overage_actual: palette.coral[500],
  budgets_chart_fill_overage_forecast: palette.coral[100],
  budgets_chart_fill_overage_historical: palette.coral[100],
  budgets_chart_fill_underspend: palette.nightfall[400],
  budgets_chart_reference_line: palette.nightfall[400],
  budgets_chart_stroke: palette.gray[800],
  budgets_expand_button_background_color: palette.palm[400],
  compute_vizibility_aggregation: palette.alpenglow[300],
  cud_chart_fill_coverable_cost: palette.nightfall[400],
  cud_chart_fill_existing_coverage: palette.alpenglow[300],
  cud_chart_fill_recommendation: palette.palm[500],
  cud_chart_fill_recommendation_max_savings: palette.oasis[500],
  cud_chart_fill_total_spend: palette.nightfall[100],
  cud_color_1yr_stroke: palette.nightfall[500],
  cud_color_1yr: palette.nightfall[400],
  cud_color_3yr_stroke: palette.palm[500],
  cud_color_3yr: palette.palm[400],
  cud_color_on_demand: palette.gray[500],
  data_table_border: palette.gray[50],
  date_picker_day_name_color: palette.gray[800],
  default_button_text_color: palette.gray[1000],
  eco_impact: palette.palm[400],
  fiscal_calendar_month_background_past: palette.gray[200],
  fiscal_calendar_month_header_background_past: palette.gray[300],
  fiscal_calendar_month_header_background: palette.gray[200],
  modal_background_color: palette.gray[0],
  net_cost_table_cell_background_color: palette.gray[0],
  ramp_plans_actuals: palette.gray[1000],
  ramp_plans_derived_projection: palette.nightfall[400],
  ramp_plans_projected_stroke: palette.gray[600],
  ramp_plans_projected: palette.gray[500],
  recommendations_chart_line_color: palette.nightfall[500],
  recommendations_meter_color_active: palette.palm[500],
  recommendations_meter_color_as_designed: palette.alpenglow[300],
  recommendations_meter_color_snoozed: palette.nightfall[700],
  report_display_grip_color: palette.gray[500],
  report_favorite_color_hover: palette.poppy[100],
  report_favorite_color: palette.poppy[300],
  resource_selector_background_hover: "rgba(30,135,255, 0.3)",
  resource_selector_border: palette.skyBlue[500],
  section_card_border: palette.gray[200],
  side_nav_text_color: palette.gray[600],
  side_nav_text_color_hover: palette.gray[1100],
  tab_text_color: palette.gray[600],
  toast_text_color: palette.gray[1000],
  top_nav_background_color: palette.gray[0],
};

export const featureSpecificSizesAndPositions = {
  skeletonLoaderBorderRadius: sizesAndPositions.borderRadius_1.replace(
    "px",
    ""
  ),
};

const stylePaths = {
  logo_path: "/assets/img/logo-standard.svg",
};

const baseTheme = {
  // Defaults
  ...fontStyles,
  ...sizesAndPositions,
  ...featureSpecificSizesAndPositions,

  // Theme Specific
  ...globalColors,
  ...featureSpecificColors,
  ...stylePaths,
};

export const defaultTheme = baseTheme;

export type Theme = typeof defaultTheme;
