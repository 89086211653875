import { css, Global, useTheme } from "@emotion/react";
import React, { PropsWithChildren } from "react";

const GlobalStyles = (props: PropsWithChildren): JSX.Element => {
  const theme = useTheme();

  return (
    <>
      <Global
        styles={css`
          @font-face {
            font-family: Ternary;
            src: url("/assets/fonts/aeonik/Aeonik-Bold.woff2") format("woff2");
            font-display: auto;
            font-style: normal;
            font-weight: 700;
            unicode-range: U+003F-007F;
          }

          @font-face {
            font-family: Ternary;
            src: url("/assets/fonts/aeonik/Aeonik-BoldItalic.woff2")
              format("woff2");
            font-display: auto;
            font-style: italic;
            font-weight: 700;
            unicode-range: U+003F-007F;
          }

          @font-face {
            font-family: Ternary;
            src: url("/assets/fonts/aeonik/Aeonik-Light.woff2") format("woff2");
            font-display: auto;
            font-style: normal;
            font-weight: 100;
            unicode-range: U+003F-007F;
          }

          @font-face {
            font-family: Ternary;
            src: url("/assets/fonts/aeonik/Aeonik-LightItalic.woff2")
              format("woff2");
            font-display: auto;
            font-style: italic;
            font-weight: 100;
            unicode-range: U+003F-007F;
          }

          @font-face {
            font-family: Ternary;
            src: url("/assets/fonts/aeonik/Aeonik-Medium.woff2") format("woff2");
            font-display: auto;
            font-style: normal;
            font-weight: 600;
            unicode-range: U+003F-007F;
          }

          @font-face {
            font-family: Ternary;
            src: url("/assets/fonts/aeonik/Aeonik-Regular.woff2")
              format("woff2");
            font-display: auto;
            font-style: normal;
            font-weight: 400;
            unicode-range: U+003F-007F;
          }

          @font-face {
            font-family: Ternary;
            src: url("/assets/fonts/aeonik/Aeonik-RegularItalic.woff2")
              format("woff2");
            font-display: auto;
            font-style: italic;
            font-weight: 400;
            unicode-range: U+003F-007F;
          }

          //
          // Numbers
          //

          @font-face {
            font-family: Ternary;
            src: url("/assets/fonts/sf/SFUIDisplay-Regular.ttf")
              format("truetype");
            font-display: auto;
            font-style: normal;
            font-weight: 400;
            unicode-range: U+0020-003E;
          }

          @font-face {
            font-family: Ternary;
            src: url("/assets/fonts/sf/sf-ui-display-bold-58646a511e3d9.otf")
              format("opentype");
            font-display: auto;
            font-style: normal;
            font-weight: 700;
            unicode-range: U+0020-003E;
          }

          @font-face {
            font-family: Ternary;
            src: url("/assets/fonts/sf/sf-ui-display-light-58646b33e0551.otf")
              format("opentype");
            font-display: auto;
            font-style: normal;
            font-weight: 100;
            unicode-range: U+0020-003E;
          }

          @font-face {
            font-family: Ternary;
            src: url("/assets/fonts/sf/sf-ui-display-semibold-58646eddcae92.otf")
              format("opentype");
            font-display: auto;
            font-style: normal;
            font-weight: 600;
            unicode-range: U+0020-003E;
          }

          * {
            box-sizing: border-box !important;
            transition:
              color 0.25s,
              background-color 0.25s,
              font-weight 0.25s;
          }

          body {
            background-color: ${theme.background_color} !important;
            font-family: Ternary, sans-serif;
            margin: 0;
            width: 100%;
          }

          h1 {
            font-size: ${theme.h1_fontSize} !important;
            font-weight: ${theme.h1_fontWeight} !important;
          }

          h2 {
            font-size: ${theme.h2_fontSize} !important;
            font-weight: ${theme.h2_fontWeight} !important;
          }

          h3 {
            font-size: ${theme.h3_fontSize} !important;
            font-weight: ${theme.h3_fontWeight} !important;
          }

          h4 {
            font-size: ${theme.h4_fontSize} !important;
            font-weight: ${theme.h4_fontWeight} !important;
          }

          h5 {
            font-size: ${theme.h5_fontSize} !important;
            font-weight: ${theme.h5_fontWeight} !important;
          }

          h6 {
            font-size: ${theme.h6_fontSize} !important;
            font-weight: ${theme.h6_fontWeight} !important;
          }

          span {
            font-size: ${theme.fontSize_ui} !important;
            font-weight: ${theme.fontWeight_regular} !important;
          }

          input {
            font-family: inherit;
          }

          textarea {
            font-family: inherit;
          }

          button {
            font-family: inherit;
          }

          p {
            font-size: ${theme.fontSize_ui};
            font-weight: ${theme.fontWeight_regular};
          }

          a {
            text-decoration: none;
            color: ${theme.link_color};
          }

          strong {
            font-size: ${theme.fontSize_ui};
          }

          em {
            font-size: ${theme.fontSize_ui};
          }

          svg {
            transition:
              color 0.01s,
              background-color 0.01s,
              font-weight 0.01s;
          }

          .recharts-legend-item {
            margin-top: 0.4rem;
          }

          .recharts-legend-wrapper {
            max-height: 4rem;
            overflow-y: auto;
          }
          .react-grid-item:hover {
            z-index: ${theme.zIndex_100};
          }
          .react-grid-item.react-grid-placeholder {
            background: ${theme.primary_color_hover};
            opacity: 0.2;
            transition-duration: 100ms;
            z-index: 2;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            -o-user-select: none;
            user-select: none;
          }
          @keyframes easeIn {
            0% {
              opacity: 0;
            }
            50% {
              opacity: 1;
              border-right: 5px solid ${theme.primary_color_background};
              border-bottom: 5px solid ${theme.primary_color_background};
            }
            100% {
              opacity: 1;
            }
          }
          .react-grid-item .react-resizable-handle {
            position: absolute;
            right: 3px;
            bottom: 3px;
            width: 15px;
            height: 15px;
            border-right: 5px solid ${theme.secondary_color_background_hover};
            border-bottom: 5px solid ${theme.secondary_color_background_hover};
            animation-name: easeIn;
            animation-duration: 2s;
          }
          .react-grid-item .react-resizable-handle:hover {
            opacity: 1;
            border-right-color: ${theme.primary_color_background};
            border-bottom-color: ${theme.primary_color_background};
          }

          .react-grid-item > .react-resizable-handle::after {
            display: none;
          }

          //
          // React Toastify
          //

          // NOTE: This is to get above literally everything else.
          :root {
            --toastify-z-index: 2000000;
          }

          .Toastify__toast {
            border-radius: ${theme.borderRadius_1};
            color: ${theme.toast_text_color};
          }

          .Toastify__toast--error {
            background-color: ${theme.feedback_negative_background};

            & svg {
              fill: ${theme.feedback_negative};
            }
          }

          .Toastify__toast--success {
            background-color: ${theme.feedback_positive_background};

            & svg {
              fill: ${theme.feedback_positive};
            }
          }

          .Toastify__toast--warning {
            background-color: ${theme.feedback_warn_background};

            & svg {
              fill: ${theme.feedback_warn};
            }
          }

          .Toastify__progress-bar--success {
            background-color: ${theme.feedback_positive};
          }
        `}
      />
      {props.children}
    </>
  );
};

export default GlobalStyles;
