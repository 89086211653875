const palette = {
  alpenglow: {
    // purple
    [0]: "#ECE0FF",
    [100]: "#CDADFF",
    [200]: "#AD7AFF",
    [300]: "#8D47FF",
    [400]: "#6E14FF",
    [500]: "#5600E0",
    [600]: "#4200AD",
    [700]: "#2F007A",
    [800]: "#1B0047",
    [900]: "#080014",
  },
  aqua: {
    // blue
    [0]: "#F0F9FF",
    [100]: "#BDE3FF",
    [200]: "#8ACEFF",
    [300]: "#57B8FF",
    [400]: "#24A3FF",
    [500]: "#008BF0",
    [600]: "#006DBD",
    [700]: "#00508A",
    [800]: "#003257",
    [900]: "#001524",
  },
  canyon: {
    // orange
    [0]: "#FFEBE0",
    [100]: "#FFCBAD",
    [200]: "#FFAA7A",
    [300]: "#FF8A48",
    [400]: "#FF6914",
    [500]: "#E05100",
    [600]: "#AD3F00",
    [700]: "#7A2C00",
    [800]: "#471A00",
    [900]: "#140700",
  },
  coral: {
    // red
    [0]: "#FFF5F8",
    [100]: "#FFD8E5",
    [200]: "#FFB6C8",
    [300]: "#FF95A6",
    [400]: "#FF7885",
    [500]: "#F35F68",
    [600]: "#CD5155",
    [700]: "#A54345",
    [800]: "#7D3636",
    [900]: "#592929",
  },
  fairway: {
    // green
    [0]: "#D8F8EB",
    [100]: "#ADF0D5",
    [200]: "#82E8BE",
    [300]: "#57E0A8",
    [400]: "#2CD892",
    [500]: "#20B176",
    [600]: "#188659",
    [700]: "#115B3C",
    [800]: "#093020",
    [900]: "#010403",
  },
  flamingo: {
    // pink
    [0]: "#FDD3E7",
    [100]: "#FAA3CD",
    [200]: "#F54297",
    [300]: "#F54297",
    [400]: "#F2127C",
    [500]: "#C70B64",
    [600]: "#96084B",
    [700]: "#660533",
    [800]: "#35031B",
    [900]: "#050002",
  },
  gray: {
    [0]: "#FEFEFF",
    [50]: "#F2F4F8",
    [100]: "#E4E8EE",
    [200]: "#DBDEE3",
    [300]: "#CCD0D5",
    [400]: "#C2C8D0",
    [500]: "#9FA8B5",
    [600]: "#7C8591",
    [700]: "#64696F",
    [800]: "#4C525B",
    [900]: "#363A40",
    [1000]: "#1E2328",
    [1100]: "#161718",
  },
  nightfall: {
    // blue
    [0]: "#F5F6FE",
    [100]: "#C6C8FB",
    [200]: "#969AF8",
    [300]: "#666CF5",
    [400]: "#363EF2",
    [500]: "#0F18E6",
    [600]: "#0C13B6",
    [700]: "#090E88",
    [800]: "#060956",
    [900]: "#080014",
  },
  oasis: {
    // teal
    [0]: "#DFFCFC",
    [100]: "#B0F7F7",
    [200]: "#82F3F3",
    [300]: "#53EEEE",
    [400]: "#24E9EA",
    [500]: "#13C7C8",
    [600]: "#0F999A",
    [700]: "#0A6A6B",
    [800]: "#063C3D",
    [900]: "#010909",
  },
  palm: {
    // green
    [0]: "#F0FFF6",
    [100]: "#A6F2C9",
    [200]: "#64E5A5",
    [300]: "#3BD58F",
    [400]: "#1BC47D",
    [500]: "#1FAC77",
    [600]: "#23936E",
    [700]: "#257B61",
    [800]: "#256252",
    [900]: "#224A40",
  },
  poppy: {
    // yellow
    [0]: "#FEF8EB",
    [100]: "#FDE7BA",
    [200]: "#FBD588",
    [300]: "#FAC456",
    [400]: "#F9B326",
    [500]: "#E49A06",
    [600]: "#B37905",
    [700]: "#815703",
    [800]: "#503602",
    [900]: "#1E1401",
  },
  pueblo: {
    // yellow
    [0]: "#FFF9EB",
    [100]: "#FFDD85",
    [200]: "#FFDD85",
    [300]: "#FFCF54",
    [400]: "#FFC01F",
    [500]: "#EBA900",
    [600]: "#B88400",
    [700]: "#855F00",
    [800]: "#523B00",
    [900]: "#1F1600",
  },
  skyBlue: {
    // blue
    [0]: "#EBF4FF",
    [100]: "#B8D9FF",
    [200]: "#85BEFF",
    [300]: "#52A3FF",
    [400]: "#1E87FF", // Ternary Blue
    [500]: "#006DEB",
    [600]: "#0056B8",
    [700]: "#003E85",
    [800]: "#002652",
    [900]: "#000E1F",
  },
  yucca: {
    // teal
    [0]: "#F5FEFD",
    [100]: "#C5FCF6",
    [200]: "#94FAEE",
    [300]: "#63F8E6",
    [400]: "#33F5DE",
    [500]: "#0BEACF",
    [600]: "#09B9A4",
    [700]: "#068677",
    [800]: "#04584E",
    [900]: "#022722",
  },
};

export default palette;
