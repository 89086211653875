import useGatekeeper from "@/hooks/useGatekeeper";
import { LinkWithSearchParams, useMatchPath } from "@/lib/react-router";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  IconDefinition,
  faChartArea,
  faChevronDown,
  faChevronUp,
  faCloud,
  faCogs,
  faExclamationTriangle,
  faHandHoldingDollar,
  faHandshake,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faMicrochip,
  faPercent,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { actions } from "@ternary/api-lib/telemetry";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex, { Props as FlexProps } from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useEffect, useRef, useState } from "react";
import copyText from "../common.copyText";
import paths from "../constants/paths";
import { useActivityTracker } from "../context/ActivityTrackerProvider";
import DataStatusIndicatorContainer from "./DataStatusIndicatorContainer";
const HEADER_HEIGHT = 100;

export const config = {
  TRANSITION_TIME: "0.2s",
  WIDTH_CLOSED: "68px",
  WIDTH_OPEN: "250px",
} as const;

type Tenant = {
  id: string;
};

interface Props {
  isOpen: boolean;
  tenant: Tenant;
  onInteraction: (interaction: SideNav.Interaction) => void;
}

interface State {
  isOpen: boolean;
}

const _StyledFlex = styled(Flex)<{ selected: boolean }>(
  ({ selected, theme }) => ({
    "&:hover p, &:hover svg": {
      color: selected ? undefined : theme.side_nav_text_color_hover,
    },
  })
);

function StyledFlex<T>({
  selected: _selected,
  ...flexProps
}: FlexProps<T> & { selected: boolean }) {
  const theme = useTheme();
  const selected = _selected as boolean;

  return (
    <_StyledFlex
      {...flexProps}
      backgroundColor={selected ? theme.primary_color_background : undefined}
      backgroundColorOnHover={
        selected ? undefined : theme.secondary_color_background
      }
      selected={selected}
    />
  );
}

// NOTE: We have dual states for isOpen (props and state)
// On hover, only state.isOpen will become true.
// On click, both state.isOpen and props.isOpen become true (and the parent will do what it needs to)

export function SideNav(props: Props): JSX.Element {
  const theme = useTheme();

  const currentPath = useMatchPath();
  const gatekeeper = useGatekeeper();

  //
  // State
  //

  const [state, setState] = useState<State>({ isOpen: false });
  const mergeState = getMergeState(setState);

  //
  // Side Effects
  //

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      //Ignores hotkey when input elements are in use
      if (
        event.target instanceof HTMLInputElement ||
        event.target instanceof HTMLTextAreaElement
      ) {
        return;
      }

      if (event.key === "/") {
        event.preventDefault();
        props.onInteraction({
          type: SideNav.INTERACTION_OPEN_MODAL_BUTTON_CLICKED,
        });
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  //
  // Interaction Handlers
  //

  function handleToggle() {
    if (props.isOpen) {
      props.onInteraction({ type: SideNav.INTERACTION_CLOSE_BUTTON_CLICKED });
      mergeState({ isOpen: false });
    } else {
      props.onInteraction({ type: SideNav.INTERACTION_OPEN_BUTTON_CLICKED });
    }
  }

  // NOTE: This timeout is used to deal with cases where a hoverOff is imediately followed by a hoverOn
  // not using it will result in flickery behavior as the state changes back and forth unnecessarily
  const timeout = useRef<NodeJS.Timeout | null>(null);

  function handleHoverOn() {
    if (props.isOpen) return;

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    if (!state.isOpen) {
      mergeState({ isOpen: true });
    }
  }

  function handleHoverOff() {
    if (props.isOpen) return;

    timeout.current = setTimeout(() => {
      mergeState({ isOpen: false });
    }, 50);
  }

  function handleHoverOnTop() {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  }

  //
  // Render
  //

  const isOpen = props.isOpen || state.isOpen;

  const treOptions = [
    {
      href: paths._dashboards,
      selected:
        currentPath === paths._dashboard || currentPath === paths._dashboards,
      title: copyText.sideNavItemLabelDashboards,
    },
    {
      href: paths._reports,
      selected:
        currentPath === paths._reports ||
        currentPath === paths._reportBuilder ||
        currentPath === paths._reportBuilderNew,
      title: copyText.sideNavItemLabelReports,
    },
    ...(gatekeeper.canViewCostReports
      ? [
          {
            href: paths._costReports,
            selected: currentPath === paths._costReports,
            title: copyText.sideNavItemLabelCostReports,
          },
        ]
      : []),
  ];

  const costAssistOptions = [
    ...(gatekeeper.canListBudgets
      ? [
          {
            href: paths._budgets,
            selected: currentPath === paths._budgets,
            title: copyText.sideNavItemLabelBudgets,
          },
        ]
      : []),
    {
      href: paths._forecasting,
      selected: currentPath === paths._forecasting,
      title: copyText.sideNavItemLabelForecasting,
    },
    {
      href: paths._costAssistRampPlans,
      selected: currentPath === paths._costAssistRampPlans,
      title: copyText.sideNavItemLabelCostAssistRampPlans,
    },
    ...(gatekeeper.canViewCostCompare
      ? [
          {
            href: paths._costAssistCostCompare,
            selected:
              currentPath === paths._costAssistCostCompare ||
              currentPath === paths._costAssistCostCompareTakeout,
            title: copyText.sideNavItemLabelCostAssistCostCompare,
          },
        ]
      : []),
    {
      href: paths._costAssistReallocations,
      selected: currentPath === paths._costAssistReallocations,
      title: copyText.sideNavItemLabelCostAssistReallocations,
    },
  ];

  const insightsOptions = [
    {
      href: paths._insightsCompute,
      selected: currentPath.includes(paths._insightsCompute),
      title: copyText.sideNavItemLabelInsightsCompute,
    },
    {
      href: paths._insightsDatabase,
      selected: currentPath.includes(paths._insightsDatabase),
      title: copyText.sideNavItemLabelInsightsDatabase,
    },
    {
      href: paths._insightsDataWarehouse,
      selected: currentPath.includes(paths._insightsDataWarehouse),
      title: copyText.sideNavItemLabelInsightsDataWarehouse,
    },
    {
      href: paths._insightsKubernetes,
      selected: currentPath.includes(paths._insightsKubernetes),
      title: copyText.sideNavItemLabelInsightsKubernetes,
    },
    {
      href: paths._insightsStorage,
      selected: currentPath.includes(paths._insightsStorage),
      title: copyText.sideNavItemLabelInsightsStorage,
    },
  ];

  const commitmentDiscountOptions = [
    {
      href: paths._commitmentDiscountAWS,
      selected: currentPath === paths._commitmentDiscountAWS,
      title: copyText.sideNavItemLabelCommitmentDiscountsAWS,
    },
    {
      href: paths._commitmentDiscountAzure,
      selected: currentPath === paths._commitmentDiscountAzure,
      title: copyText.sideNavItemLabelCommitmentDiscountsAzure,
    },
    {
      href: paths._commitmentDiscountGCP,
      selected: currentPath === paths._commitmentDiscountGCP,
      title: copyText.sideNavItemLabelCommitmentDiscountsGCP,
    },
  ];

  const mspManagementOptions = [
    {
      href: paths._mspAdmin,
      selected:
        currentPath === paths._mspMgmt ||
        currentPath === paths._mspAdmin ||
        currentPath === paths._mspAdminTenantUsers,
      title: copyText.sideNavItemLabelCustomerManagement,
    },
    {
      href: paths._mspBillingRulesEngine,
      selected: currentPath === paths._mspBillingRulesEngine,
      title: copyText.sideNavItemLabelMspBillingRulesEngine,
    },
    {
      href: paths._mspDashboard,
      selected: currentPath === paths._mspDashboard,
      title: copyText.sideNavItemLabelDashboard,
    },
  ];

  return (
    <Box
      backgroundColor={theme.panel_backgroundColor}
      height="100vh"
      paddingHorizontal={theme.space_sm}
      paddingVertical={theme.space_xxs}
      transition={`${config.TRANSITION_TIME} width`}
      width={isOpen ? config.WIDTH_OPEN : config.WIDTH_CLOSED}
    >
      <Flex
        alignItems="center"
        height={64}
        justifyContent={isOpen ? "space-between" : "center"}
        paddingHorizontal={theme.space_sm}
        width="100%"
        onMouseEnter={handleHoverOnTop}
        onMouseLeave={handleHoverOff}
      >
        <LinkWithSearchParams to={paths._home}>
          <img
            alt={copyText.companyName}
            src={theme.logo_path}
            width={isOpen ? "126.875px" : 0}
            height={isOpen ? "28px" : 0}
          />
        </LinkWithSearchParams>
        <Box cursor="pointer" onClick={handleToggle}>
          <Flex alignItems="center" justifyContent="flex-end">
            {isOpen && (
              <Icon
                color={theme.side_nav_text_color}
                icon={faLongArrowAltLeft}
                size="sm"
              />
            )}
            <Box
              backgroundColor={theme.side_nav_text_color}
              height={18}
              marginHorizontal={theme.space_xxs}
              width={2}
            />
            {!isOpen && (
              <Icon
                color={theme.side_nav_text_color}
                icon={faLongArrowAltRight}
                size="sm"
              />
            )}
          </Flex>
        </Box>
      </Flex>
      <Flex
        direction="column"
        justifyContent="space-between"
        height={`calc(100% - ${HEADER_HEIGHT}px)`}
        marginTop={theme.space_md}
        overflowY="auto"
        overflowX={!isOpen ? "hidden" : "auto"}
        width="100%"
        onMouseEnter={handleHoverOn}
        onMouseLeave={handleHoverOff}
      >
        <Box>
          <Flex
            alignItems="center"
            backgroundColor={theme.secondary_color_background}
            backgroundColorOnHover={theme.secondary_color_background_hover}
            borderRadius={theme.borderRadius_2}
            cursor="pointer"
            justifyContent="space-between"
            marginBottom={theme.space_xxs}
            paddingRight={theme.space_sm}
            paddingVertical={theme.space_sm}
            width="100%"
            onClick={() =>
              props.onInteraction({
                type: SideNav.INTERACTION_OPEN_MODAL_BUTTON_CLICKED,
              })
            }
          >
            <Flex>
              <Flex alignItems="center" justifyContent="center" width={44}>
                <Icon color={theme.side_nav_text_color} icon={faSearch} />
              </Flex>
              <Text color={theme.side_nav_text_color} whiteSpace="nowrap">
                {copyText.sideNavItemLabelSearch}
              </Text>
            </Flex>
          </Flex>
          <SideNavItem
            href={paths._home}
            icon={faCloud}
            label={copyText.sideNavItemLabelHome}
            selected={currentPath === paths._home}
          />
          <SideNavItem
            icon={faChartArea}
            isSideNavOpen={isOpen}
            label={copyText.sideNavItemLabelReportingEngine}
            options={treOptions}
          />
          {gatekeeper.canViewCostAssist && (
            <SideNavItem
              icon={faHandHoldingDollar}
              isSideNavOpen={isOpen}
              label={copyText.sideNavItemLabelCostAssist}
              options={costAssistOptions}
            />
          )}
          <SideNavItem
            icon={faMicrochip}
            isSideNavOpen={isOpen}
            label={copyText.sideNavItemLabelInsights}
            options={insightsOptions}
          />
          {gatekeeper.canViewCommittedUsePage && (
            <SideNavItem
              icon={faPercent}
              isSideNavOpen={isOpen}
              label={copyText.sideNavItemLabelCommitmentDiscounts}
              options={commitmentDiscountOptions}
            />
          )}
          <SideNavItem
            href={paths._alertTracking}
            icon={faExclamationTriangle}
            label={copyText.sideNavItemLabelAlertTracking}
            selected={currentPath === paths._alertTracking}
          />
          {gatekeeper.canAccessMspAdmin && (
            <SideNavItem
              icon={faHandshake}
              isSideNavOpen={isOpen}
              label={copyText.sideNavItemLabelMspManagement}
              options={mspManagementOptions}
            />
          )}
          {gatekeeper.canViewAdminPage && (
            <SideNavItem
              href={paths._admin}
              icon={faCogs}
              label={copyText.sideNavItemLabelAdmin}
              selected={currentPath === paths._admin}
            />
          )}
        </Box>
        <Flex justifyContent="center" width="100%">
          <DataStatusIndicatorContainer isSideNavOpen={isOpen} />
        </Flex>
      </Flex>
    </Box>
  );
}

interface Option {
  href: string;
  selected: boolean;
  title: string;
}

interface SideNavItemProps {
  href?: string;
  icon: IconDefinition;
  isSideNavOpen?: boolean;
  label: string;
  options?: Option[];
  selected?: boolean;
}

function SideNavItem(props: SideNavItemProps): JSX.Element {
  const activityTracker = useActivityTracker();
  const theme = useTheme();

  const isOptionSelected = props.options
    ? props.options.some((option) => option.selected)
    : false;

  const [isExpanded, setIsExpanded] = useState(
    isOptionSelected && props.isSideNavOpen
  );

  const selected = props.options
    ? (isOptionSelected && !isExpanded) ||
      (!props.isSideNavOpen && isOptionSelected)
    : props.selected;

  useEffect(() => {
    if (!props.isSideNavOpen || !isOptionSelected) {
      setIsExpanded(false);
      return;
    }

    setIsExpanded(true);
  }, [props.isSideNavOpen]);

  function handleClickItem(): void {
    if (!props.options) {
      activityTracker.captureAction(actions.SIDE_NAV_ITEM_SELECT, {
        item: props.label,
      });
      return;
    }
    setIsExpanded(!isExpanded);
  }

  return (
    <Box width="100%">
      <StyledFlex
        as={props.href ? LinkWithSearchParams : undefined}
        alignItems="center"
        selected={!!selected}
        borderRadius={theme.borderRadius_2}
        cursor="pointer"
        justifyContent="space-between"
        marginBottom={theme.space_xxs}
        paddingRight={theme.space_sm}
        paddingVertical={theme.space_sm}
        to={props.href ?? ""}
        width="100%"
        onClick={handleClickItem}
      >
        <Flex>
          <Flex alignItems="center" justifyContent="center" width={44}>
            <Icon
              color={
                selected ? theme.text_color_inverse : theme.side_nav_text_color
              }
              icon={props.icon}
            />
          </Flex>
          <Text
            color={
              selected ? theme.text_color_inverse : theme.side_nav_text_color
            }
            fontWeight={selected ? theme.fontWeight_bold : undefined}
            whiteSpace="nowrap"
          >
            {props.label}
          </Text>
        </Flex>
        {props.options && (
          <Icon
            clickable
            color={
              selected ? theme.text_color_inverse : theme.side_nav_text_color
            }
            icon={isExpanded ? faChevronUp : faChevronDown}
            size="sm"
          />
        )}
      </StyledFlex>
      {isExpanded && props.options && (
        <Box paddingHorizontal={theme.space_xs}>
          {props.options.map((option, i) => (
            <StyledFlex
              key={i}
              alignItems="center"
              as={LinkWithSearchParams}
              selected={!!option.selected}
              borderRadius={theme.borderRadius_2}
              height={40}
              marginLeft={theme.space_lg}
              marginVertical={theme.space_xxs}
              paddingHorizontal={theme.space_sm}
              paddingVertical={theme.space_xxs}
              to={option.href}
              onClick={() => {
                activityTracker.captureAction(actions.SIDE_NAV_ITEM_SELECT, {
                  item: option.title,
                  parent: props.label,
                });
              }}
            >
              <Text
                color={
                  option.selected
                    ? theme.text_color_inverse
                    : theme.side_nav_text_color
                }
              >
                {option.title}
              </Text>
            </StyledFlex>
          ))}
        </Box>
      )}
    </Box>
  );
}

SideNav.INTERACTION_OPEN_BUTTON_CLICKED =
  "SideNav.INTERACTION_OPEN_BUTTON_CLICKED" as const;

SideNav.INTERACTION_OPEN_MODAL_BUTTON_CLICKED =
  "SideNav.INTERACTION_OPEN_MODAL_BUTTON_CLICKED" as const;

SideNav.INTERACTION_CLOSE_BUTTON_CLICKED =
  "SideNav.INTERACTION_CLOSE_BUTTON_CLICKED" as const;

type InteractionOpenButtonClicked = {
  type: typeof SideNav.INTERACTION_OPEN_BUTTON_CLICKED;
};

type InteractionOpenModalButtonClicked = {
  type: typeof SideNav.INTERACTION_OPEN_MODAL_BUTTON_CLICKED;
};

type InteractionCloseButtonClicked = {
  type: typeof SideNav.INTERACTION_CLOSE_BUTTON_CLICKED;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace SideNav {
  export type Interaction =
    | InteractionOpenButtonClicked
    | InteractionOpenModalButtonClicked
    | InteractionCloseButtonClicked;
}

export default SideNav;
